import { For, Suspense } from 'solid-js';
import { Button, Container, Heading, Link, Page, Section, TextLink } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { createAsync } from '@solidjs/router';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import { Grid, GridHalf } from '../../../components/layouts/grid';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { FacilityCard } from '../../../components/facility/card';
import type { RouteDefinition } from '@solidjs/router';

export default function TroonExecutiveCard() {
	const data = createAsync(() => getFacilities({ slugs }), { deferStream: true });

	return (
		<>
			<Title>Troon Executive Card - Play golf in the UAE | Troon</Title>
			<Hero src={`${getConfigValue('IMAGE_HOST')}/web/hero/executive-card.jpg`}>
				<Heading as="h1">Troon Executive Card</Heading>
			</Hero>

			<Container>
				<Page>
					<Section>
						<Heading as="h2">Play golf in the UAE</Heading>
						<Heading as="p" size="h5">
							Online Tee Times in the UAE with the Troon Executive Card
						</Heading>
						<p>
							Access online tee times with the Troon Executive Card in the UAE, Oman, Egypt and India. The Troon
							Executive Card is your ultimate pass to golf at great Troon golf courses, ready for you to play at the
							lowest rates. Play golf at the stunning Al Zorah Golf Club in Ajman or Night Golf in Dubai at The Track,
							Meydan Golf. Book online tee times and play golf in Dubai at exceptional golf courses including The Els
							Club Dubai, Dubai Hills Golf Club and Montgomerie Golf Club Dubai. The Troon Executive Card will also give
							you access to play Troon golf courses across the Middle East, including LA VIE Club and Al Mouj Golf in
							Oman and Madinaty Golf Club in Egypt.
						</p>
					</Section>

					<Grid>
						<GridHalf>
							<Section>
								<Heading as="h2" size="h5">
									Purchase Via The Troon International App
								</Heading>
								<ul class="flex flex-wrap gap-4">
									<li>
										<Button
											as={Link}
											href="https://apps.apple.com/us/app/troon-international/id1212067574"
											target="_blank"
										>
											Download for iOS
										</Button>
									</li>
									<li>
										<Button
											as={Link}
											href="https://play.google.com/store/apps/details?id=link.troon.app&hl=en"
											target="_blank"
										>
											Download for Android
										</Button>
									</li>
								</ul>
							</Section>
						</GridHalf>
						<GridHalf class="order-first">
							<Section>
								<p>The benefits of the Troon Executive Card are second to none:</p>

								<ul class="flex list-disc flex-col gap-2 ps-6">
									<li>
										Savings on great golfing experiences, for you and a guest at participating Troon golf courses*
									</li>
									<li>Access to Tournaments and Group Clinics</li>
									<li>Discount on F&B and Merchandise at selected Troon Golf facilities</li>
									<li>7-day booking window</li>
									<li>Valid for 12-months from the date of purchase</li>
								</ul>

								<p class="text-sm italic">
									*from 1st June until 30th September, you can bring up to 3 guests at the same Troon Executive Card
									Rate. See <TextLink href="/card/troon-executive-card/terms">full program terms & conditions</TextLink>
									.
								</p>
							</Section>
						</GridHalf>
					</Grid>

					<Section>
						<Heading as="h2">Participating Facilities</Heading>

						<Suspense>
							<ul class="grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4">
								<For each={data()?.facilities.facilities}>
									{(facility) => (
										<li>
											<FacilityCard level="h3" disableFavorites facility={facility} />
										</li>
									)}
								</For>
							</ul>
						</Suspense>
					</Section>
				</Page>
			</Container>
		</>
	);
}

const slugs: Array<string> = [
	'montgomerie-golf-club-dubai',
	'al-mouj-golf',
	'arabian-ranches-golf-club',
	'dubai-hills-golf-club',
	'the-els-club-dubai',
	'the-track-meydan-golf',
	'al-zorah-golf-club',
	'al-hamra-golf-club',
	'la-vie-club',
	'the-allegria-golf-club',
	'madinaty-golf-cub',
	'prestige-golfshire-club',
];

const query = gql(`query executiveCardFacilities($slugs: [String!]) {
  facilities: facilitiesV3(idOrSlugs: $slugs, sortBy: name) {
    facilities {
      ...FacilityCard
    }
  }
}`);

const getFacilities = cachedQuery(query);

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
